import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('liveblogscheme', () => ({
  init () {
    console.log("Denne side benytter liveblog tema");
    const interval = setInterval(() => {
      const themeColorDiv = document.getElementById('theme-color');

      if (themeColorDiv) {
        console.log('Elementet blev fundet!', themeColorDiv);
        
        // Kontrollér, at der er en anden klasse til farvekoden
        const colorCode = themeColorDiv.classList[1];
        if (colorCode) {
          console.log(colorCode);

          // Stop intervallet, da elementet er fundet
          clearInterval(interval);

          const thememain = document.getElementById('theme-main');
          const thememainsecondary = document.getElementById('theme-main-second');
          const themesecondary = document.getElementById('theme-secondary');

          // Kontrollér, at elementerne findes, før du ændrer stil
          if (thememain) {
            thememain.style.backgroundColor = colorCode;
          }
          if (thememainsecondary) {
            thememain.style.backgroundColor = colorCode;
          }
          if (themesecondary) {
            themesecondary.style.backgroundColor = colorCode; // Eller en anden farve
          }
        } else {
          console.log("Farvekoden blev ikke fundet i klasselisten.");
        }
      }
    }, 50); // Tjek hvert halve sekund
  },
}));
